import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion'

const PricingFaq = () => {
    return (
        <div>
                <div className="container">
                <div className="section-title">
                    <h3>Frequently Asked Questions</h3>
                </div>
                    <div className="faq-accordion">
                        <Accordion>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q1. What payment options are available?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    With us you have the option to pay via credit card (Visa and Mastercard). Payment is made via the respective payment provider to ensure optimal security and purchase processing.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q2. Can I change my plan? Can I upgrade to a larger license at any time?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    If your project is bigger than expected, you can upgrade your plan at any time via your personal dashboard.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q3. Can I cancel at any time?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    If you don ‘t need visionar anymore, you can cancel your subscription at any time via your personal dashboard. Click on “Cancel” in your subscription overview. Your plan will be cancelled automatically. Also, the automatic payments will be stopped. You will then be redirected to our feedback page. We are always happy to receive valuable input from our users to improve our services.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q4. Are there any discounts?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    There are discounts when you choose an annual subscription. Here you can save up to 10% of the costs. Of course, from time to time there are goodies or special promotions for our loyal newsletter subscribers.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q5. How secure is the payment process?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    The payment process is carried out by the external provider “Stripe”. Stripe, Inc ensures the secure processing of electronic payments in national and international markets.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q6. How can I manage my billing?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    In your personal dashboard you have access to all your data, orders, invoices, and your subscription. Here you can make changes at any time.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q7. What if I need more than 100 targets?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    Contact our support team via e-mail: support@visionar.com
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q8. Do you offer trial versions?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    Sure! You can test all of our plans 14 days for free.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q9. Do you offer refunds?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    The product can be tested in advance at any time free of charge. For this reason, there is no refund of the purchase price planned. If there are any problems with the product, please contact our support team via e-mail: support@visionar.com.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                    </div>
                </div>
            </div>
    )
}

export default PricingFaq