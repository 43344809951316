import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Pricing from "../components/Index/Pricing"
import PricingFaq from "../components/Pricing/PricingFaq"
import ProjectStartArea from '../components/Index/ProjectStartArea';

const PricingPage = () => {
    return (
        <Layout>
            <Navbar />
            <Pricing />
            <PricingFaq />
            <ProjectStartArea />
            <Footer />
        </Layout>
    );
}

export default PricingPage