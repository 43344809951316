import React, { useState } from 'react'
import { Link } from 'gatsby'
import starIcon from '../../assets/images/star-icon.svg'
import enterpriseIcon from '../../assets/icons/enterprise.svg'

const Pricing = () => {
    const [annualDiscount, setAnnualDiscount] = useState(false);

    return (
        <div className="pricing-table-area">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="pricing" />
                        Pricing Plans
                    </span>
                    <h2>Try visionar FREE for 14 days</h2>
                    <p>Cancel or downgrade your plan at any time. No contracts. No hidden fees.</p>
                </div>
                <div className="btn-pricing-switch-container">
                    <div className="btn-group btn-group-toggle" data-toggle="buttons">
                        <label className={`btn btn-outline-primary btn-pricing-switch ${!annualDiscount ? "active" : ""}`}>
                            <input type="radio" onClick={() => setAnnualDiscount(false)} name="options" id="option1" autoComplete="off" /> Monthly Plans
                        </label>
                        <label className={`btn btn-outline-primary btn-pricing-switch ${annualDiscount ? "active" : ""}`} style={{borderTopRightRadius: '0.25em', borderBottomRightRadius: '0.25em'}}>
                            <input type="radio" onClick={() => setAnnualDiscount(true)} name="options" id="option2" autoComplete="off" /> Annual discount
                        </label>
                        <div className="annual-discount-banner">Save 10%</div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div className="single-pricing-box">
                            <div className="pricing-header">
                                <h3>Basic</h3>
                                <p style={{fontSize: '15px', paddingTop: '16px', color: ''}}>For individuals or small businesses just getting started with Augmented Reality</p>
                            </div>
                            <div className="price">
                                <sup>€</sup> {annualDiscount ? 42 : 47} <sub>/ Per month</sub>
                            </div>

                            <ul className="pricing-features">
                                <li><span>10</span> Targets / Scenes </li>
                                <li><i className='bx bxs-badge-check'></i> Unlimited Projects</li>
                                <li><i className='bx bxs-badge-check'></i> Image Files</li>
                                <li><i className='bx bxs-badge-check'></i> Audio Files</li>
                                <li><i className='bx bxs-badge-check'></i> Video Files (720p)*</li>
                                <li><i className='bx bxs-x-circle red'></i> Links & Buttons</li>
                                <li><i className='bx bxs-x-circle red'></i> Green Screen</li>
                                <li><i className='bx bxs-x-circle red'></i> Priority Support</li>
                            </ul>

                            <div className="btn-box" style={{display: 'flex', justifyContent: 'center'}}>
                                <a href={`${process.env.GATSBY_STUDIO_URL}/signup?plan=1&yearly=${annualDiscount ? "true" : "false"}`} className="default-btn" style={{padding: '12px 25px'}}>
                                    Try for free
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 most-popular-grid">
                        <div className="single-pricing-box single-pricing-box-most-popular">
                            <div style={{
                                backgroundColor: '#1C68FF',
                                color: '#fff',
                                height: '48px',
                                borderTopLeftRadius: '0.25em',
                                borderTopRightRadius: '0.25em',
                            }}>
                                <span style={{lineHeight: '48px'}}>Most popular</span>
                            </div>
                            <div className="pricing-header">
                                <h3>Standard</h3>
                                <p style={{fontSize: '15px', paddingTop: '16px', color: ''}}>For growing teams and businesses that need more space and advanced features</p>
                            </div>

                            <div className="price">
                                <sup>€</sup> {annualDiscount ? 87 : 97} <sub>/ Per month</sub>
                            </div>

                            <ul className="pricing-features">
                                <li><span>25</span> Targets / Scenes </li>
                                <li><i className='bx bxs-badge-check'></i> Unlimited Projects</li>
                                <li><i className='bx bxs-badge-check'></i> Image Files</li>
                                <li><i className='bx bxs-badge-check'></i> Audio Files</li>
                                <li><i className='bx bxs-badge-check'></i> Video Files (1080p)*</li>
                                <li><i className='bx bxs-badge-check'></i> Links & Buttons</li>
                                <li><i className='bx bxs-x-circle red'></i> Green Screen</li>
                                <li><i className='bx bxs-x-circle red'></i> Priority Support</li>
                            </ul>

                            <div className="btn-box" style={{display: 'flex', justifyContent: 'center'}}>
                                <a href={`${process.env.GATSBY_STUDIO_URL}/signup?plan=2&yearly=${annualDiscount ? "true" : "false"}`} className="default-btn" style={{padding: '12px 25px'}}>
                                    Try for free
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                        <div className="single-pricing-box">
                            <div className="pricing-header">
                                <h3>Premium</h3>
                                <p style={{fontSize: '15px', paddingTop: '16px', color: ''}}>For companies that need an all-in-one solution to tackle complex projects</p>
                            </div>

                            <div className="price">
                                <sup>€</sup> {annualDiscount ? 267 : 297} <sub>/ Per month</sub>
                            </div>

                            <ul className="pricing-features">
                                <li><span>100</span> Targets / Scenes </li>
                                <li><i className='bx bxs-badge-check'></i> Unlimited Projects</li>
                                <li><i className='bx bxs-badge-check'></i> Image Files</li>
                                <li><i className='bx bxs-badge-check'></i> Audio Files</li>
                                <li><i className='bx bxs-badge-check'></i> Video Files (1080p)*</li>
                                <li><i className='bx bxs-badge-check'></i> Links & Buttons</li>
                                <li><i className='bx bxs-badge-check'></i> Green Screen</li>
                                <li><i className='bx bxs-badge-check'></i> Priority Support</li>
                            </ul>

                            <div className="btn-box" style={{display: 'flex', justifyContent: 'center'}}>
                                <a href={`${process.env.GATSBY_STUDIO_URL}/signup?plan=3&yearly=${annualDiscount ? "true" : "false"}`} className="default-btn" style={{padding: '12px 25px'}}>
                                    Try for free
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 offset-lg-0 offset-md-0">
                        <div className="single-pricing-box">
                            <div className="pricing-header">
                                <h3>Enterprise</h3>
                                <p style={{fontSize: '15px', paddingTop: '16px', color: ''}}>For companies with advanced security, branding and support needs</p>
                            </div>

                            <div className="price">
                                {/*<sup>€</sup> 297 <sub> / Per month</sub>*/}
                                <img style={{ height: '48px', paddingBottom: '5px' }} src={enterpriseIcon} />
                            </div>

                            <ul className="pricing-features">
                                <li>Everything in <b>Premium</b>, plus: </li>
                                <li><span>100+</span> Targets / Scenes </li>
                                <li><i className='bx bxs-badge-check'></i> Android SDK</li>
                                <li><i className='bx bxs-badge-check'></i> iOS SDK</li>
                            </ul>

                            <div className="btn-box" style={{display: 'flex', justifyContent: 'center'}}>
                                <a href='mailto:support@visionar.com' className="default-btn" style={{padding: '12px 25px'}}>
                                    Contact us
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <p style={{color: '#919191'}}>* The offer is subjected to the <Link style={{color: '#919191'}} to="/terms-of-service">Fair Use Policy</Link>.</p>
            </div>
        </div>
    )
}

export default Pricing
